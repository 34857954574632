import React, { useContext, useEffect, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useColorModeValue, useSafeArea, Box, Text, Button, Input, AlertDialog } from 'native-base'

import { StackActions } from '@react-navigation/native';

import Constants from 'expo-constants'

import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from "expo-firebase-recaptcha";
import { getAuth, RecaptchaVerifier, PhoneAuthProvider, sendSignInLinkToEmail, isSignInWithEmailLink } from 'firebase/auth';

import * as Google from 'expo-auth-session/providers/google';
import * as Facebook from 'expo-auth-session/providers/facebook'

import * as Device from 'expo-device'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as RecaptchaContext } from '../context/RecaptchaContext'
import { Context as AuthContext } from '../context/AuthContext'

import { isValidPhoneNumber, normalizePhoneNumber } from '../api/signin/phone';
import { isValidEmailAddress } from '../api/signin/emailLink';

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
import Spacer from '../components/Spacer'
// Set the key-value pairs for the different languages you want to support.

const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const recaptchaUsageLimit = 3

const SigninScreen = ( { navigation, route }) => {

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const recaptchaCtx = useContext(RecaptchaContext)

    const authCtx = useContext(AuthContext)

    const recaptchaVerifier = useRef(null)
    const recaptchaUsage = useRef(0)
    const cancelRefWindowMessage = useRef()
    const dialogMessage = useRef('')
    
    const [authData, setAuthData] = useState('')
    const [phoneVerificationId, setPhoneVerificationId] = useState("")
    const [phoneVerificationCode, setPhoneVerificationCode] = useState("")
    const [signinWithLinkOnEmailInProgress, setSigninWithLinkOnEmailInProgress] = useState(false)
    const [isOpenWindowMessage, setIsOpenWindowMessage] = useState(false)

    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const recaptchaExpired = () => {

        recaptchaCtx.setToken(null)
    }

    const resetRecaptcha = async () => {

        if (!recaptchaVerifier.current) {

            return;
        }
                
        if (!Device.brand) {
    
            await recaptchaVerifier.current.clear()
    
            recaptchaVerifier.current = new RecaptchaVerifier(
                getAuth(), 
                'recaptcha-container', {
                    'expired-callback': recaptchaExpired
                }
            )
        }                
    
        recaptchaUsage.current = 0
    
        setAuthData('')
    
        recaptchaExpired()
    }

    const updateRecaptchaLimit = () => {

        recaptchaUsage.current = recaptchaUsage.current + 1

        if (recaptchaUsage.current >= recaptchaUsageLimit) {
            
            resetRecaptcha()            
        }
    }

    const authenticate = async () => {

        if (isValidPhoneNumber(authData)) {

            try {

                const phoneAuthProvider = new PhoneAuthProvider(getAuth())

                console.log("Recaptcha verifier: ", recaptchaVerifier.current)

                const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                    normalizePhoneNumber(authData),
                    recaptchaVerifier.current
                )

                setPhoneVerificationId(verificationId)

                return ;
            } catch (error) {

                // Set message window text
                dialogMessage.current = i18n.t('smsNotSent')
                // Open a message window
                setIsOpenWindowMessage(true) 
            }
        }

        if (isValidEmailAddress(authData)) {

            const actionCodeSettings = {
                // URL you want to redirect back to. The domain (www.example.com) for this
                // URL must be in the authorized domains list in the Firebase Console.
                url: Constants.expoConfig.EmailRedirectURL,
                handleCodeInApp: true 
            };

            
            sendSignInLinkToEmail(getAuth(), authData, actionCodeSettings)
            .then(result => {
                setSigninWithLinkOnEmailInProgress(true)
                window.localStorage.setItem('emailForSignIn', authData);
            })
            .catch(error => {

                console.log("Error: ", error)

                // Set message window text
                dialogMessage.current = i18n.t('emailNotSent')
                // Open a message window
                setIsOpenWindowMessage(true)
            })                    
        }

        updateRecaptchaLimit()
    }

    // It may let Recaptcha in an inconsistent state
    // const cancelAuthentication = async () => {
        
    //     await resetRecaptcha()

    //     authCtx.setSigninError({error: 'Canceled by user'})

    //     navigation.dispatch(
    //         StackActions.replace("SigninFlow")
    //     )
    // }

    // Initialize Recaptcha at startup
    useEffect(() => {

        if (authCtx.state && authCtx.state.signinError) {

            return
        }

        if (!Device.brand) {

            recaptchaVerifier.current = new RecaptchaVerifier(
                getAuth(), 
                'recaptcha-container', {
                    'expired-callback': recaptchaExpired
                }
            )
        }
    }, [])

    // If an error was set in Authentication Context
    useEffect(() => {

        if (authCtx.state && authCtx.state.signinError) {

            // Set message window text
            dialogMessage.current = i18n.t('invalidAuthentication')
            // Open a message window
            setIsOpenWindowMessage(true)

            authCtx.signout()

            resetRecaptcha()
        }
    }, [])

    // If Recaptcha is null, trigger a validation
    // when Recaptcha Context is changed
    useEffect(() => {

        // if Recaptcha Verifier does not exit, return
        if (!recaptchaVerifier.current) {

            return;
        }
        
        // If Recaptcha state is null, trigger a validation
        if (!recaptchaCtx.state) {

            (async () => {
        
                recaptchaCtx.setToken(await recaptchaVerifier.current.verify())
            })()
        }        
    }, [recaptchaCtx.state])

    // Initialize google
    // TODO: Get from a file that is not on git
    const [googleRequest, googleResponse, googlePromptAsync] = Google.useIdTokenAuthRequest(
        {

            // host.exp.exponent
            // iosClientId: `529231627599-drbc95jveufkefdovvuu44nj81jvhepn.apps.googleusercontent.com`,            
            // androidClientId: `403726724671-92dljjbfvcu3g8nacl9nks7sl2a9ejl2.apps.googleusercontent.com`,
            
            webClientId: Constants.expoConfig.GoogleWebClientId,

            //expoClientId: `403726724671-92dljjbfvcu3g8nacl9nks7sl2a9ejl2.apps.googleusercontent.com`,
            //clientId: `529231627599-619grotpljmcht0f9bg4sf983pa89s63.apps.googleusercontent.com`,
            scopes: ['email', 'profile'],
            language: languageCtx.state
        }
    );

    // Initialize facebook
    // TODO: Get from a file that is not on git
    const [fbRequest, fbResponse, fbPropmptAsync] = Facebook.useAuthRequest({
        clientId: '286192410944221',
        language: languageCtx.state
    })

    // Parse Google Authentication
    useEffect(() => {

        if (googleResponse && googleResponse.type === 'success') {

            recaptchaCtx.setToken(null)

            authCtx.signin({ 
                authProviderName: "google.com", 
                navigation,
                authResponse: googleResponse,
                loadingScreenContent: <Text>{i18n.t('wait')}</Text>
            })
        }
    }, [googleResponse]); // call AuthContext after google response

    // Parse Facebook Authentication
    useEffect(() => {

        if (fbResponse && fbResponse.type === 'success') {

            authCtx.signin({ 
                authProviderName: "facebook.com", 
                navigation, 
                authResponse: fbResponse,
                loadingScreenContent: <Text>{i18n.t('wait')}</Text>
            })
        }
    }, [fbResponse]); // call AuthContext after facebook response

    // Parse email/link authentication on the second tab
    useEffect(() => {
        
        let email = window.localStorage.getItem('emailForSignIn');

        // On the second tab
        if (isSignInWithEmailLink(getAuth(), window.location.href) && email) {

            if (!authCtx.state.signinError) {

                authCtx.signin({
                    authProviderName: "emailLink", 
                    authDetails: {
                        email,
                        currentLocation: window.location.href
                    },
                    navigation,
                    loadingScreenContent: <Text>{i18n.t('wait')}</Text>
                })
            } else {

                // Set message window text
                dialogMessage.current = i18n.t('emailAuthenticationFailed')
                // Open a message window
                setIsOpenWindowMessage(true)                
            }

            setSigninWithLinkOnEmailInProgress(false)
        }
    }, [])

    return (
        <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
            <Text>Signin Screen</Text>

            {/* <Text>{recaptchaCtx.state}</Text> */}
            
            <Spacer/>
            <Input 
                value={authData}
                onChangeText={value => setAuthData(value)}
                onSubmitEditing={ async () => {

                    if (!recaptchaCtx.state || phoneVerificationId || signinWithLinkOnEmailInProgress || (!isValidPhoneNumber(authData) && !isValidEmailAddress(authData))) {

                        // Set message window text
                        dialogMessage.current = i18n.t('invalidAuthenticationData')
                        // Open a message window
                        setIsOpenWindowMessage(true)  

                        return ;
                    }

                    await authenticate()
                }}
                disabled={!recaptchaCtx.state || signinWithLinkOnEmailInProgress || phoneVerificationId} 
                size="xl" 
                placeholder={i18n.t('emailAddress') + " " + i18n.t('or') + " " + i18n.t('phoneNumber')}
                style={{backgroundColor: !isValidPhoneNumber(authData) && !isValidEmailAddress(authData) ? "" : "lightgreen"}}
            />
            {/* <Text>is valid email: {JSON.stringify(isValidEmailAddress(authData))}</Text>
            <Text>is valid phone: {JSON.stringify(isValidPhoneNumber(authData))}</Text> */}
        
            <Button disabled={!recaptchaCtx.state || phoneVerificationId || signinWithLinkOnEmailInProgress || (!isValidPhoneNumber(authData) && !isValidEmailAddress(authData))} 
                onPress={async () => {

                    await authenticate()
                }}
            >
                {i18n.t('authentication')}
            </Button>

            {   phoneVerificationId ?
                <Box>
                    <Input 
                        value={phoneVerificationCode}
                        onChangeText={value => setPhoneVerificationCode(value)}
                        size="xl" 
                        placeholder={i18n.t('verificationCode')}
                    />
                    <Button disabled={phoneVerificationCode.length != 6} onPress={() => {

                        authCtx.signin({
                            authProviderName: "phone", 
                            authDetails: {
                                phoneVerificationId,
                                phoneVerificationCode,
                                phoneNumber: normalizePhoneNumber(authData)
                            },
                            navigation,
                            loadingScreenContent: <Text>{i18n.t('wait')}</Text>
                        })

                        setPhoneVerificationCode("")

                        updateRecaptchaLimit()
                    }}>
                        {i18n.t('verify') + "!"}
                    </Button>
                </Box> : null
            }

            {
                signinWithLinkOnEmailInProgress || phoneVerificationId ? <Button onPress={() => {

                    if (signinWithLinkOnEmailInProgress) {

                        setAuthData("")
                        setSigninWithLinkOnEmailInProgress(false)
                    }

                    if (phoneVerificationId) {

                        resetRecaptcha()
                        setPhoneVerificationId("")
                        setPhoneVerificationCode("")
                    }
                    
                }}>
                    {i18n.t('cancel')}
                </Button> : null
            }

            <Spacer/>
            {googleRequest ? <Button disabled={!recaptchaCtx.state || phoneVerificationId || signinWithLinkOnEmailInProgress} onPress={async () => {

                const response = await googlePromptAsync({useProxy: false, showInRecents: true, })
                
                // Successful authetication processed in useEffect
                if (response.type != 'success') {

                    updateRecaptchaLimit()
                }
                
            }}>
                Google
            </Button> : null}

            <Spacer/>
            {fbRequest ? <Button disabled={!recaptchaCtx.state || phoneVerificationId || signinWithLinkOnEmailInProgress} onPress={async () => {

                const response = await fbPropmptAsync()

                // Successful authetication processed in useEffect
                if (response.type != 'success') {

                    updateRecaptchaLimit()
                }
            }}>
                Facebook
            </Button> : null}

            <Spacer/>
            <Button disabled={!recaptchaCtx.state || phoneVerificationId || signinWithLinkOnEmailInProgress} onPress={() => {

                authCtx.signin({
                    authProviderName: "yahoo.com", 
                    authDetails: {
                        language: languageCtx.state
                    },
                    navigation,
                    loadingScreenContent: <Text>{i18n.t('wait')}</Text>
                })
                
                updateRecaptchaLimit()
            }}>
                Yahoo
            </Button>

            <FirebaseRecaptchaVerifierModal
                ref={recaptchaVerifier}
                firebaseConfig={{
                    apiKey: "AIzaSyA8TAheDwaScFaLctgHOySY9xE5EzmCACM",
                    authDomain: "e-noa-ee0b4.firebaseapp.com",
                    projectId: "e-noa-ee0b4",
                    storageBucket: "e-noa-ee0b4.appspot.com",
                    messagingSenderId: "403726724671",
                    appId: "1:403726724671:web:d14a52d9c857e71cdf928a",
                    measurementId: "G-L4T8719XLQ"
                }}
            />

            <FirebaseRecaptchaBanner /> 

            <AlertDialog leastDestructiveRef={cancelRefWindowMessage} isOpen={isOpenWindowMessage} onClose={() => {
                console.log("on Close Alert Dialog")

                setIsOpenWindowMessage(false) 
                // If authentication failed, remove parameters from URL
                window.location.href = Constants.expoConfig.EmailRedirectURL
            }}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header>{i18n.t('error')}</AlertDialog.Header>
                    <AlertDialog.Body>
                        
                        <Text>{dialogMessage.current}</Text>

                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button colorScheme="danger" onPress={ () => {

                                setIsOpenWindowMessage(false)

                                if (isSignInWithEmailLink(getAuth(), window.location.href)) {

                                    authCtx.signout()
                                    
                                    // If authentication failed, remove parameters from URL
                                    window.location.href = Constants.expoConfig.EmailRedirectURL
                                }
                            }} >
                                Ok
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        </Box>
    )
}

const styles = StyleSheet.create({ })


export default SigninScreen;

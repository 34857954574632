import { getAuth, onAuthStateChanged } from 'firebase/auth'

import { StackActions } from '@react-navigation/native';

import createDataContext from './createDataContext';

import { googleSignin } from '../api/signin/google'
import { facebookSignin } from '../api/signin/facebook'
import { emailCodeSignin } from '../api/signin/emailCode'
import { emailLinkSignin } from '../api/signin/emailLink';
import { phoneSignin } from '../api/signin/phone'
import { yahooSignin } from '../api/signin/yahoo';


const authReducer = (state, action) => {
    
    // console.log("\nauthReducer: " + JSON.stringify(action))

    switch (action.type) {
        case 'signin': 
            console.log("\n\n Signin auth Reducer: " + JSON.stringify(action.payload))
            return { ...action.payload }
        
        case 'signout':
            return {  }

        case 'signinError': 
            return {signinError: action.payload}
        default:
            return state;
    }
}

const tryAlreadySignin = dispatch => navigation => {

    // IMPORTANT: In development when the app is reloded the callback is registered several times
    // in order to have it registered once, reload the application!

    // Use StackActions.replace in order not to have a back arrow on the screen

    // get Auth for default app; to get for custom app  getAuth(getApp(appName))
    const auth = getAuth()

    onAuthStateChanged(
        auth,
        async firebaseUser => {

            console.log("\n\n!!!!! Authenticated user: " + JSON.stringify(firebaseUser))

            console.log("\nCurrent user: " + JSON.stringify(auth.currentUser))

            if (!firebaseUser) {

                if (!auth.currentUser) {

                    navigation.dispatch(
                        StackActions.replace("SigninFlow")
                    )
                }

                return;
            }

            // TODO: Fetch data from Members table
            const firestoreMember = firebaseUser

            if (!firestoreMember) {

                console.error("\n\nInvalid Agg Person: " + JSON.stringify(firestoreMember))

                signout(dispatch)(firestoreMember)

                return;
            }

            // Go to Account screen after registration
            navigation.dispatch(
                StackActions.replace("AuthenticatedAppFlow")
            )
            
            // Update context from a context function 
            dispatch({
                type: 'signin',
                payload: firebaseUser
            })      
        }
    )

}


const signin = dispatch => async ({ authProviderName, authDetails, navigation, authResponse, loadingScreenContent }) => {

    console.log("Go to Loading!")
    navigation.dispatch(
        StackActions.replace(
            "Loading",
            {
                loadingScreenContent
            }
        )
    )

    let signinError;

    try {
        switch (authProviderName) {
            case "google.com":
                
                signinError = await googleSignin(authResponse)
    
                break
    
            case "facebook.com":
    
                signinError = await facebookSignin(authResponse)
                
                break
            
            case "emailCode":
                // DOES NOT receive `navigation` as it is not used for registration
                // it is used just for signin
                signinError = await emailCodeSignin(authDetails.email)
                
                break

            case "emailLink":

                signinError = await emailLinkSignin(authDetails)

                break
    
            case "phone":

                signinError = await phoneSignin(authDetails)
                
                break

            case "yahoo.com":
                
                signinError = await yahooSignin(authDetails.language)
                
                break
    
            default:
                break;
        }    
    } catch (error) {

        signinError = error
    }
    
    if (signinError) {

        console.log("Signin result Error: " + signinError)

        // Keep as a sample how to call a function from context inside of context
        setSigninError(dispatch)(signinError)

        navigation.dispatch(
            StackActions.replace("SigninFlow")
        )

    } else {

        console.log("Successful sign in !!!")

        // Do nothing, onAuthStateChanged callback from tryAlreadySignin
        // deals with successfull signin, it also calls dispatch
    }
    
}

// TODO Send current personId from RTDB
const signout = dispatch => async () => {

    console.log("Requested signout!")

    await getAuth().signOut()

    dispatch({ type: 'signout' })
}


const setSigninError = dispatch => signinError => {

    dispatch({type: 'signinError', payload: signinError})
}

const reset = dispatch => () => {
    dispatch({type: 'reset'})
}


export const { Provider, Context } = createDataContext(
    authReducer,
    { tryAlreadySignin, signin, signout, setSigninError , reset},
    { } // TODO update AuthContext initial value
);
export default {
    theme: "Tema",
    lightTheme: "Tema deschisa",
    darkTheme: "Tema inchisa",
    back: "Inapoi",
    settings: "Setari",
    romanian: "Romana",
    hungarian: "Maghiara",
    authentication: "Autentificare",
    emailAddress: 'Adresa de email',
    or: "sau",
    phoneNumber: "Numar de Telefon",
    signout: "Delogare",
    verificationCode: "Cod de Verificare",
    verify: "Verifica",
    cancel: "Anuleaza",
    msgSuccessfulAuthentication: "Te-ai autentificat cu succes. Inchide acest tab si continua in tab-ul initial",
    closeThisTab: 'Inchide acest tab',
    emailAuthenticationFailed: "Autetificarea cu email a esuat",
    error: "Eroare",
    wait: "Asteapta",
    smsNotSent: "SMS-ul un a putut fi trimis",
    emailNotSent: "email-ul nu a putut fi trimis",
    invalidAuthentication: "Autentificare nereusita",
    invalidAuthenticationData: "Email sau numar de telefon incorect"
}
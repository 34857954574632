export default {
    theme: "HU-Tema",
    lightTheme: "HU-Tema deschisa",
    darkTheme: "HU-Tema inchisa",
    back: "HU-Inapoi",
    settings: "HU-Setari",
    romanian: "HU-Romana",
    hungarian: "HU-Maghiara",
    authentication: "HU-Autentificare",
    emailAddress: 'HU-Adresa de email',
    or: "vagy",
    phoneNumber: "HU-Numar de Telefon",
    signout: "HU-Delogare",
    verificationCode: "HU-Cod de Verificare",
    verify: "HU-Verifica",
    cancel: "HU-Anuleaza",
    msgSuccessfulAuthentication: "HU-Te-ai autentificat cu succes. Inchide acest tab si continua in tab-ul initial",
    closeThisTab: 'HU-Inchide acest tab',
    emailAuthenticationFailed: "HU-Autetificarea cu email a esuat",
    error: "HU-Eroare",
    wait: "HU-Asteapta",
    smsNotSent: "HU-SMS-ul un a putut fi trimis",
    emailNotSent: "HU-email-ul nu a putut fi trimis",
    invalidAuthentication: "HU-Autentificare nereusita",
    invalidAuthenticationData: "HU-Email sau numar de telefon incorect"
}

import { getApp } from 'firebase/app'

import { getAuth, signInWithCredential, GoogleAuthProvider } from 'firebase/auth'

// import googleConfig from '../../../googleConfig';

// Returns error or falsy value on success
const googleSignin = async ( response ) => {

    const { id_token } = response.params;    

    const providerAuthenticationResult = response

    if (!providerAuthenticationResult || providerAuthenticationResult.type !== 'success') {

        return "Signin canceled by user"
    }

    const credential = getFirebaseGoogleAuthCredential(id_token)

    const firebaseAuthenticationResult = await firebaseGoogleSignin(credential)

    console.log("Google firebaseAuthenticationResult: " + JSON.stringify(firebaseAuthenticationResult))

    if (!firebaseAuthenticationResult || firebaseAuthenticationResult.error) {

        error = "Unable to register Google Authentication to Firebase. "  + JSON.stringify(firebaseRegistrationResult ? firebaseRegistrationResult.error : "Account disabled")

        console.log(error)

        return error
    }

    console.log("Google Successfuly authenticated")

    return null
}

const getFirebaseGoogleAuthCredential = accessToken => {

    console.log("Google Auth access Token: " + JSON.stringify(accessToken))

    return GoogleAuthProvider.credential(accessToken)
}

const firebaseGoogleSignin = async (credential) => {

    console.log("Google Credential: " + JSON.stringify(credential))

    return await signInWithCredential(getAuth(), credential)
}

export { googleSignin }
import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { useColorModeValue, useSafeArea, Box, Text } from 'native-base'
import { isSignInWithEmailLink, getAuth } from 'firebase/auth'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
// Set the key-value pairs for the different languages you want to support.

const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const HomeScreen = ( { navigation }) => {

    const authCtx = useContext(AuthContext)
    
    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    if (isSignInWithEmailLink(getAuth(), window.location.href)) {

        return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
            <Text>
                {i18n.t('msgSuccessfulAuthentication')}
            </Text>
        </Box>
    }

    return (
        <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
            <Text>Home Screen</Text>
            <Text>{authCtx.state.email}</Text>
            <Text>{authCtx.state.phoneNumber}</Text>
            <Text>{authCtx.state && authCtx.state.providerData ? authCtx.state.providerData[0].email : null}</Text>
        </Box>
    )
}

const styles = StyleSheet.create({ })

export default HomeScreen;
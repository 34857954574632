
import { getAuth, signInWithPopup, OAuthProvider, signInWithCredential } from 'firebase/auth';


// Returns error or falsy value on success
const yahooSignin = async language => {

    const result = await doYahooSignin(language)

    if (!result) {

        return {
            error: "Canceled by user"
        }
    }


    const credential = getFirebaseYahooAuthCredential(result)
       
    console.log("Yahoo Auth Credential: " + JSON.stringify(credential))
   
    const firebaseAuthenticationResult = await firebaseYahooSignin(credential);

    console.log("Yahoo firebaseAuthenticationResult: " + JSON.stringify(firebaseAuthenticationResult))

    if (!firebaseAuthenticationResult || firebaseAuthenticationResult.error) {

        error = "Unable to register Yahoo Authentication to Firebase. "  + JSON.stringify(firebaseRegistrationResult ? firebaseRegistrationResult.error : "Account disabled")

        console.log(error)

        return {error}
    }

    // TODO Update Firebase RTDB and fetch current person data

    console.log("Yahoo Successfuly authenticated")

    return null
}

const getFirebaseYahooAuthCredential = result => {

    return OAuthProvider.credentialFromResult(result);
      
}

const firebaseYahooSignin = async (credential) => {

    console.log("Yahoo Credential: " + JSON.stringify(credential))

    return await signInWithCredential(getAuth(), credential)
}

const doYahooSignin = async language => {

    const provider = new OAuthProvider('yahoo.com');

    provider.setCustomParameters({
        // Prompt user to re-authenticate to Yahoo.
        prompt: 'login',
        language
      });
    
    return signInWithPopup(getAuth(), provider)
        .then(result => result)
        .catch(error => {
            console.log("Error: ", error)
            return null
        })
}

export { yahooSignin }
import { getAuth, signInWithEmailLink } from "firebase/auth";

const emailLinkSignin = async authDetails => {

    const firebaseAuthenticationResult = await firebaseEmailLinkSignin(authDetails);

    console.log("Email/Link firebaseAuthenticationResult: " + JSON.stringify(firebaseAuthenticationResult))

    if (!firebaseAuthenticationResult || firebaseAuthenticationResult.error) {

        error = "Unable to register Email/Link Authentication to Firebase. "  + JSON.stringify(firebaseRegistrationResult ? firebaseRegistrationResult.error : "Account disabled")

        console.log(error)

        return {error}
    }

    // TODO Update Firebase RTDB and fetch current person data

    console.log("Email/Link Successfuly authenticated")

    window.localStorage.removeItem('emailForSignIn');

    return null
}

const firebaseEmailLinkSignin = async ({email, currentLocation}) => {

    
    return await  signInWithEmailLink(getAuth(), email, currentLocation)
}

const isValidEmailAddress = emailAddress => /\S+@\S+\.\S{2,}/.test(emailAddress)

export { emailLinkSignin, isValidEmailAddress }
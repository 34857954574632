import { initializeAuth, getReactNativePersistence } from 'firebase/auth';
import { getApp, getApps, initializeApp } from 'firebase/app';
import ReactNativeAsyncStorage from '@react-native-async-storage/async-storage';

import firebase from 'firebase/compat/app'
import * as Device from 'expo-device'

import Constants from 'expo-constants'

const initApp = () => {


    console.log("Firebase config: ", 
        Constants.expoConfig.FirebaseConfig
    )

    if (Device.brand) {
        
        initializeApp(
            Constants.expoConfig.FirebaseConfig
        )

        const app = getApp();
        try{
            const auth = initializeAuth(app, {
                persistence: getReactNativePersistence(ReactNativeAsyncStorage)
            });
        } catch (e) {

        }
    } else {

        firebase.initializeApp(
            Constants.expoConfig.FirebaseConfig
        )
    }
}

export default initApp
import React, { useContext, useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import { StyleSheet, LogBox } from 'react-native';

import Constants from 'expo-constants'

import { useColorMode, NativeBaseProvider } from 'native-base';

import ResolveAuthScreen from './src/screens/ResolveAuthScreen';
import LoadingScreen from './src/screens/LoadingScreen';
import SigninScreen from './src/screens/SigninScreen';
import HomeScreen from './src/screens/HomeScreen';
import SettingsScreen from './src/screens/SettingsScreen';

import { Provider as LanguageProvider } from './src/context/LanguageContext'
import { Provider as ThemeProvider } from './src/context/ThemeContext'
import { Provider as AuthProvider } from './src/context/AuthContext'
import { Provider as RecaptchaProvider } from './src/context/RecaptchaContext'

import { Context as LanguageContext } from './src/context/LanguageContext'
import { Context as ThemeContext } from './src/context/ThemeContext'
import { Context as AuthContext } from './src/context/AuthContext'

import {
  setLanguageContext,
  setThemeContext
} from './src/api/setAppContext';
import AppHeader from './src/components/AppHeader';

import initApp from './src/api/initApp';
initApp()

import * as WebBrowser from 'expo-web-browser'
WebBrowser.maybeCompleteAuthSession()

function SigninFlowNavigator( { navigation } ) {

  return (
    <Stack.Navigator initialRouteName="Signin"
      screenOptions={{
        title: Constants.expoConfig.AppTitle,
        headerStyle:{
          backgroundColor: "#00ffff"
        }
      }}
      
      >
      <Stack.Screen 
        name="Signin" 
        component={SigninScreen}
        options={{ 
          headerTitle: (props) => <AppHeader navigation={navigation} hideSignout={true} {...props} /> 
        }}
      />

      <Stack.Screen 
        name="Settings" 
        component={SettingsScreen}
        options={{ 
          headerTitle: (props) => <AppHeader navigation={navigation} hideSignout={true} hideSettings={true} {...props} /> 
        }}

      />
    </Stack.Navigator>
  )
}

function AuthenticatedAppFlowNavigator( { navigation } ) {

  const authCtx = useContext(AuthContext)

  return (
    <Stack.Navigator initialRouteName="Home"
      screenOptions={{
        title: Constants.expoConfig.AppTitle,
        headerStyle:{
          backgroundColor: "#00ffff"
        }
      }}
    >
      <Stack.Screen 
        name="Home" 
        component={HomeScreen}
        options={{ 
          headerTitle: (props) => <AppHeader navigation={navigation} {...props}/>
        }}
      />

      <Stack.Screen 
        name="Settings" 
        component={SettingsScreen}
        options={{
          headerTitle: (props) => <AppHeader hideSettings={true} navigation={navigation} {...props}/>
        }}
      />
    </Stack.Navigator>
  )

}

function AppFlowNavigator () {

  // This updates all app when language or theme change
  const languageCtx = useContext(LanguageContext)
  const themeCtx = useContext(ThemeContext)

  const { setColorMode } = useColorMode()

  useEffect(() => {

      setThemeContext(themeCtx, setColorMode)
      
  }, []);

  useEffect(() => {

      setLanguageContext(languageCtx)
      
  }, []);

  return (
    <Stack.Navigator initialRouteName="ResolveAuth"
      screenOptions={{
        title: Constants.expoConfig.AppTitle,
        headerStyle:{
          backgroundColor: "#00ffff"
        }
      }}
    >
      <Stack.Screen
        name="ResolveAuth"
        component={ResolveAuthScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen 
        name="SigninFlow" 
        component={SigninFlowNavigator}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen 
        name="AuthenticatedAppFlow" 
        component={AuthenticatedAppFlowNavigator}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen 
        name="Loading" 
        component={LoadingScreen}
        options={{ 
          headerShown: false
        }}
      />
    </Stack.Navigator>
  )

}

const Stack = createStackNavigator();

export default function App() {

  LogBox.ignoreAllLogs(true)

  return (
    <NativeBaseProvider><LanguageProvider><ThemeProvider><AuthProvider><RecaptchaProvider>
      <NavigationContainer>
          <AppFlowNavigator/>
      </NavigationContainer>
      </RecaptchaProvider></AuthProvider></ThemeProvider></LanguageProvider></NativeBaseProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});


import { getAuth,  FacebookAuthProvider, signInWithCredential } from 'firebase/auth'


// Returns error or falsy value on success
const facebookSignin = async response => {

    console.log("facebookSignin - response: " + response)

    const { access_token } = response.params;

    const providerAuthenticationResult = response

    if (!providerAuthenticationResult || providerAuthenticationResult.type !== 'success') {

        return "Signin canceled by user"
    }

    const credential = getFirebaseFacebookAuthCredential(access_token)

    console.log("Facebook credential: ", credential)

    const firebaseAuthenticationResult = await firebaseFacebookSignin(credential)

    console.log("Facebook firebaseAuthenticationResult: " + JSON.stringify(firebaseAuthenticationResult))
    
    if (!firebaseAuthenticationResult || firebaseAuthenticationResult.error) {

        error = "Unable to register Facebook Authentication to Firebase. "  + JSON.stringify(firebaseRegistrationResult ? firebaseRegistrationResult.error : "Account disabled")

        console.log(error)

        return error
    }

    console.log("Facebook Successfuly authenticated")

    // TODO Update Firebase RTDB and fetch current person data

    return null
}


const getFirebaseFacebookAuthCredential = accessToken => {

    console.log("Facebook Auth access Token: " + JSON.stringify(accessToken))

    return FacebookAuthProvider.credential(accessToken);
}

const firebaseFacebookSignin = async (credential) => {

    console.log("Facebook Credential: " + JSON.stringify(credential))

    return await signInWithCredential(getAuth(), credential)
}

export { facebookSignin }